// Import packages from React, React-Bootstrap, icons from assets folder
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import github from "../assets/github.png";
import linkedin from "../assets/linkedin.png";
import outlook from "../assets/outlook.png";

// Contact Page - use of 3 icons and hyperlinks to external social media sites
const Contact = () => {
  return (
    <div className="contact-section">
      <div className="d-block w-100 coverimg4">
        <Container>
          <Row className="contact-section1">
            <Col className="contact-me" md={3} xs={12}>
              <h1>Contact Me!</h1>
            </Col>
            <Col className="github" md={3} xs={3}>
              <a
                href="https://github.com/cheungty1"
                target="_blank"
                rel="noreferrer"
              >
                <img src={github} alt="notfound" />
              </a>
            </Col>

            <Col className="linkedin" md={3} xs={3}>
              <a
                href="https://www.linkedin.com/in/tait-cheung-266b234a/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedin} alt="notfound" />
              </a>
            </Col>

            <Col className="outlook" md={3} xs={3}>
              <a
                href="mailto:cheungty1@hotmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={outlook} alt="notfound" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
