// Import React modules
import React from "react";
import { Link } from "react-router-dom";

// Import npm packages
import { Container, Button, Card, Col, Row } from "react-bootstrap";

// Import Portfolio images for projects demo
import Img1 from "../assets/enseren.png";
import Img2 from "../assets/kitty-cat-cafe.png";
import Img3 from "../assets/travelpedia.png";
import Img4 from "../assets/anime-quote-generator.png";
import Img5 from "../assets/anime-quote-library.png";
import Img6 from "../assets/cryptofi.png";
import Img7 from "../assets/discord.png";
import Img8 from "../assets/weather-app.png";
import Img9 from "../assets/iworld-cover.png";
import background1 from "../assets/414339_2614138066384_782785776_o.jpg";

// About/Projects Page - Showcases projects created, using bootstrap card + buttons, and pictures
const Projects = () => {
  return (
    <div className= "projects-page">
      <div className="hero-section">
        <Container>
          <div className="hero-section1">
            <div className="hero-section-text">
              <h1>taiyc1/cheungty1 - Tait's Portfolio</h1>
              <p>
                Enseren - Enjoy Serendipity! Welcome to my site, pls feel free
                to check out my work!
              </p>
              <Link to="/contact">
                <Button variant="dark">Contact Me</Button>
              </Link>
            </div>
            <img src={background1} alt="profile" />
          </div>
        </Container>
      </div>

      <div className="portfolio-section">
        <Container>
          <Row className="portfolio-section-heading">
            <h2>Portfolio/Projects: </h2>
          </Row>
          <Row xs={1} md={2} className="g-4">
            <Col>
              <Card>
                <Card.Img variant="top" src={Img1} />
                <Card.Body>
                  <Card.Title>React/SASS Portfolio Site</Card.Title>
                  <Card.Text>
                    Portfolio website written in React/SASS which features React
                    Components and SASS (CSS pre-processor).
                  </Card.Text>
                  <Button variant="primary" href="#">
                    Live Site
                  </Button>{" "}
                  <Button variant="primary" href="#">
                    Source Code
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card>
                <Card.Img variant="top" src={Img2} />
                <Card.Body>
                  <Card.Title>HTML/CSS - Kitty Cat Cafe</Card.Title>
                  <Card.Text>
                    HTML/CSS site - featuring a tourism website for a Japanese
                    Cat Cafe with a target audience for international
                    travellers.
                  </Card.Text>
                  <Button
                    variant="primary"
                    href="https://enseren.com/html_kitty-cat-cafe/"
                  >
                    Live Site
                  </Button>{" "}
                  <Button
                    variant="primary"
                    href="https://github.com/cheungty1/f-end-assess2-v1.26-submit"
                  >
                    Source Code
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card>
                <Card.Img variant="top" src={Img3} />
                <Card.Body>
                  <Card.Title>PHP CMS - TravelPedia</Card.Title>
                  <Card.Text>
                    PHP - Tourism Site: featuring a full CMS written in PHP that
                    allows travellers to blog about their journeys and
                    experiences with photo uploads and written entries.
                  </Card.Text>
                  <Button variant="primary" href="https://enseren.com/php-cms/">
                    Live Site
                  </Button>{" "}
                  <Button
                    variant="primary"
                    href="https://github.com/cheungty1/cms-assess2.2381-submitted"
                  >
                    Source Code
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card>
                <Card.Img variant="top" src={Img4} />
                <Card.Body>
                  <Card.Title>JS - Anime Quote Generator</Card.Title>
                  <Card.Text>
                    JS - Anime Quote Website: Written in HTML/CSS and Javascipt
                    linked to an API Endpoint which generates Anime Quotes from
                    the users' desired search.
                  </Card.Text>
                  <Button
                    variant="primary"
                    href="https://enseren.com/js_anime-quotes-gen/"
                  >
                    Live Site
                  </Button>{" "}
                  <Button
                    variant="primary"
                    href="https://github.com/cheungty1/anime-quotes4.32"
                  >
                    Source Code
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card>
                <Card.Img variant="top" src={Img7} />
                <Card.Body>
                  <Card.Title>Discord - Weather App</Card.Title>
                  <Card.Text>
                    As part of a group hackathon assignment, we successfully
                    integrated a weather API into the hackathon software
                    ecosystem to deliver a useful and handy weather app.
                  </Card.Text>
                  <Button variant="primary" href="#">
                    Live Site
                  </Button>{" "}
                  <Button
                    variant="primary"
                    href="https://github.com/cheungty1/hackathon-master"
                  >
                    Source Code
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card>
                <Card.Img variant="top" src={Img8} />
                <Card.Body>
                  <Card.Title>React - Weather App</Card.Title>
                  <Card.Text>
                    Using ReactJS and linked to a public weather + unsplash
                    image API, this weather app allows users to search for
                    current weather and 3 day forecasts for major cities around
                    the world.
                  </Card.Text>
                  <Button variant="primary" href="https://react-weather-app.enseren.com/">
                    Live Site
                  </Button>{" "}
                  <Button variant="primary" href="https://github.com/cheungty1/weather-app-3.1372-final-submit">
                    Source Code
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col className="mb-3">
              <Card>
                <Card.Img variant="top" src={Img5} />
                <Card.Body>
                  <Card.Title>
                    Svelte/GraphQL API - Anime Quote Library
                  </Card.Title>
                  <Card.Text>
                    <p>
                      {" "}
                      Svelte/GraphQL API - Anime Quote Website: With the
                      Front-end client written in Svelte and the back-end API in
                      GraphQL and Mongoose using MongoDB. JOI Validation was
                      also implemented in both front-end and back-end.
                    </p>

                    <p>
                      This CMS allows users to post and contribute to their
                      favourite Anime Quotes and personal information to the
                      Anime Library.
                    </p>

                    <p>
                      Users can also further customize their entries with
                      additional CRUD operations such as add, edit and deletion
                      of quotes for a greater and enhanced experience!
                    </p>
                  </Card.Text>
                  <Button variant="primary" href="#">
                    Live Site
                  </Button>{" "}
                  <Button
                    variant="primary"
                    href="https://github.com/cheungty1/anime-quotes_tait9.130-final1"
                  >
                    Source Code
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col className="mb-3">
              <Card>
                <Card.Img variant="top" src={Img6} />
                <Card.Body>
                  <Card.Title>React/NodeJS API- CryptoFi</Card.Title>
                  <Card.Text>
                    <p>
                      {" "}
                      React/NodeJS API Cryptocurrency website: Technologies Used
                      include ReactJS, NodeJS, Express, Firebase. Other
                      dependencies and technologies used include JWT Tokens and
                      Bcrypt for user authentication and authorization
                    </p>

                    <p>
                      Featuring a Cryptocurrency Exchange Rate information page
                      from a popular Crypto API Endpoint.
                    </p>

                    <p>
                      Also, a personal customizable CBDC page that allows users
                      to post and edit their own favourite CBDC and its details.
                      The website also features the ability for users' to upload
                      and edit their profile image.
                    </p>
                  </Card.Text>
                  <Button
                    variant="primary"
                    href="https://cryptofi.enseren.com/"
                  >
                    Live Site
                  </Button>{" "}
                  <Button
                    variant="primary"
                    href="https://github.com/cheungty1/cryptofi-2.267-final-submit"
                  >
                    Source Code
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col className="mb-3">
              <Card>
                <Card.Img variant="top" src={Img9} />
                <Card.Body>
                  <Card.Title>React/NextJS API- iWorld</Card.Title>
                  <Card.Text>
                    <p>
                      {" "}
                      React/NextJS API- NewsFeed website: Technologies Used
                      include ReactJS, NodeJS, Express, NextJS hooked up to newsapi.org API 
                    </p>
                    <p>
                      Features 3 real time news feed that dynamically renders news articles 
                      on technology, economics and politics.
                    </p>
                  </Card.Text>
                  <Button
                    variant="primary"
                    href="https://taiyc1-iworld.vercel.app/"
                  >
                    Live Site
                  </Button>{" "}
                  <Button
                    variant="primary"
                    href="https://github.com/cheungty1/info-arch-portfolio-v1.632-github-version"
                  >
                    Source Code
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Projects;
