// Import packages from React, React-Bootstrap, React-Router-Dom, React-icons
import React from "react";
import { Link } from "react-router-dom";
import { FiHome } from "react-icons/fi";
import { Col, Row, Container } from "react-bootstrap";

// NotFound 404 page - text description and link to return to home page
const NotFound = () => {
  return (
    <Container>
      <div className="not-found">
        <Row className="notfound-section">
          <Col className="contact-me" md={4} xs={4}>
            <h1>404</h1>
          </Col>
          <Col className="contact-me" md={5} xs={5}>
            <p>Hmm... Sorry, this page does not exist!</p>
          </Col>
          <Col className="homeBtn" md={3} xs={3}>
            <Link to="/">
              <FiHome id="btn-icon" />
            </Link>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default NotFound;
