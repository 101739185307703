// Import packages from React, React-Bootstrap, React-Router-Dom
import React from "react";
import { Carousel, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

// Cover/Home Page - use carousel featuring 3 background images
const Home = () => {
  return (
    <div className="about-section">
      <Carousel fade>
        <Carousel.Item data-bs-interval="10000">
          <div className="d-block w-100 coverimg" />
          <Carousel.Caption className="carousel1">
            <h1>Welcome to Enseren - Enjoy Serendipity!</h1>
            <p>Hi I'm Tait! I love Web design/development!</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item data-bs-interval="10000">
          <div className="d-block w-100 coverimg2" />
          <Carousel.Caption className="carousel2">
            <h2>I love science and enjoy learning new things!</h2>
            <p>
              My other interests include philosophy, physics, computer science
              and neuroscience
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item data-bs-interval="10000">
          <div className="d-block w-100 coverimg3" />
          <Carousel.Caption className="carousel3">
            <h2>
              Thanks!{" "}
              <Link to="/projects">
                <Button className="text-right" variant="dark">
                  View Here
                </Button>
              </Link>
            </h2>
            <p>Life is too short, serendipity makes life interesting!</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Home;
